<template>
  <div class="box">
    <div class="cont">
      <div class="bar">
        <Fa icon="times" style="font-size: 25px;"/>
      </div>
      <div class="content">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectModal"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/scss/variables";

.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5000;
  background: rgba($white, .9);
  overflow: hidden;

  .cont {
    .bar {

    }
    .content {

    }
  }
}
</style>