<template>
  <div class="cont">
    <p>Ma boîte de réception est toujours ouverte. Que vous ayez une question ou que vous vouliez simplement me dire bonjour, je ferai de mon mieux pour vous répondre !</p>
    <Button :windowed="true" link="mailto:benoit@myutopie.fr">Me Dire Bonjour</Button>
    <h3>Built with <a target="_blank" href="https://fr.vuejs.org/index.html" class="link-a">VueJS</a></h3>
  </div>
</template>

<script>
import Button from "@/components/Button"

export default {
  name: "Contact",
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/scss/variables';

.cont {
  position: relative;
  display: flex;
  height: 50vh;
  flex-direction: column;

  h3 {
    position: absolute;
    bottom: 3px;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 10px;
  }
}
.link-a {
  text-decoration: none;
  color: $green;
  display: inline-block;
  position: relative;

  &:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: $green;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after { 
    width: 100%; 
    left: 0; 
  }
}
</style>