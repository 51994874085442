<template>
  <div class="contentL">
    <p>Travailleur et volontaire, je possède une très bonne faculté d'adaptation ainsi qu'une certaine aisance relationnelle.</p>
    <p>Autodidacte, vivement intéressé par les nouvelles technologies et résolument orienté vers la résolution des problématiques clients, j'ai pu appréhender rapidement plusieurs langages de programmation ainsi que les nouveaux web services.</p>
    <p>Durant cet apprentissage, je me suis spécialisé dans le développement Front-End via JavaScript, en utilisant principalement <a class="link-a" href="https://fr.vuejs.org/index.html" target="_blank">Vue JS</a>, <a class="link-a" href="https://angular.io/" target="_blank">Angular</a> et <a class="link-a" href="https://fr.reactjs.org/" target="_blank">React JS</a>, ce qui ne m'a pas empêché également de comprendre et d'analyser diverses technologies Back-end.</p>
    <h1>/ Languages</h1>
    <div class="listimg">
      <img src="@/assets/images/JS.png" alt="JS"/>
      <img src="@/assets/images/TS.png" alt="TS"/>
      <img src="@/assets/images/html.png" alt="HTML5"/>
      <img src="@/assets/images/css.png" alt="CSS3"/>
      <img src="@/assets/images/sass.png" alt="SCSS"/>
      <img src="@/assets/images/python.png" alt="Python"/>
      <img src="@/assets/images/dart.png" alt="Dart"/>
    </div>
    <h1>/ Frameworks</h1>
    <div class="listimg">
      <img src="@/assets/images/angular.png" alt="Angular"/>
      <img src="@/assets/images/vue.png" alt="VueJS"/>
      <img src="@/assets/images/nuxt.png" alt="Nuxt"/>
      <img src="@/assets/images/react.png" alt="ReactJS"/>
      <img src="@/assets/images/node.png" alt="NodeJS"/>
      <img src="@/assets/images/socket.png" alt="SocketIO"/>
      <img src="@/assets/images/firebase.png" alt="Firebase"/>
      <img src="@/assets/images/Strapi.png" alt="Strapi"/>
      <img src="@/assets/images/flutter.png" alt="Flutter"/>
    </div>
    <h1>/ Outils</h1>
    <div class="listimg">
      <img src="@/assets/images/linux.png" alt="Linux"/>
      <img src="@/assets/images/git.png" alt="GitHub"/>
      <img src="@/assets/images/vscode.png" alt="VsCode"/>
      <img src="@/assets/images/apple.png" alt="Apple"/>
      <img src="@/assets/images/yarn.png" alt="Yarn"/>
      <img src="@/assets/images/npm.png" alt="Npm"/>
      <img src="@/assets/images/webpack.png" alt="Webpack"/>
    </div>
  </div>
  <div class="contentR">
    <div class="image">
      <div class="imgOverlay"></div>
      <img src="@/assets/images/me.jpeg" alt="Moi"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Moi"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";
h1 {
  margin-top: 25px;
}
.listimg {
  margin-bottom: 15px;
  margin-top: -5px;

  img {
    width: 35px;
    margin-right: 10px;
    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.3);
    }
  }
}
.link-a {
  text-decoration: none;
  color: $green;
  display: inline-block;
  position: relative;

  &:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: $green;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after { 
    width: 100%; 
    left: 0; 
  }
}
.contentL {
  margin-right: 10px;
}
.contentR {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-left: 50px;
  margin-top: 25px;

  .imgOverlay {
    position: absolute;
    background: rgba($green, .5);
    backdrop-filter: contrast(500%);
    width: 200px;
    height: 200px;
    border-radius: 8px;
    transition: opacity .4s ease-in-out;

    &:hover {
      opacity: 0;
    }
  }
  img {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    color: rgba($green, .5);
  } 
}

@media screen and (max-width: 800px) {
  .contentL {
    width: 100%;
  }
  .contentR {
    display: none;
  }
}
</style>