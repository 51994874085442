<template>
  <div class="social">
    <Github class="icon"/>
    <Linkedin class="icon"/>
    <Email class="icon"/>
    <div class="filet"></div>
  </div>
</template>

<script>
import Github from "./Github"
import Linkedin from "./Linkedin"
import Email from "./Email"

export default {
  name: "Social",
  components: {
    Github,
    Linkedin,
    Email
  }
}
</script>

<style lang="scss" scoped>
.social {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50px;
  bottom: 0px;

  .icon {
    width: 20px;
    margin-bottom: 15px;
  }

  .filet {
    height: 80px;
    width: 1px;
    background: #8892b0;
  }
}

@media screen and (max-width: 800px) {
  .social {
    display: none;
  }
}
</style>