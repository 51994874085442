<template>
  <TopBar @change-menu="ScrollToPage"/>
  <Social />
  <div id="0">
  <Hi/>
  </div>
  <div class="upp" id="1">
  <DefaultPage title="Qui suis-je ?">
    <Moi />
  </DefaultPage>
  </div>
  <div class="upp" id="2">
  <DefaultPage title="Mes Expériences">
    <Exp />
  </DefaultPage>
  </div>
  <div class="upp" id="3">
  <DefaultPage title="Mes Projets">
    <Projets />
  </DefaultPage>
  </div>
  <div class="upp" id="4">
  <DefaultPage title="Contactez-moi">
    <Contact />
  </DefaultPage>
  </div>
</template>

<script>
import Social from './components/Social'
import TopBar from './components/TopBar'
import Hi from './pages/Hi'
import DefaultPage from './pages/DefaultPage'
import Moi from './pages/Moi'
import Exp from './pages/Exp'
import Projets from './pages/Projets'
import Contact from './pages/Contact'

export default {
  name: 'App',
  components: {
    Social,
    TopBar,
    Hi,
    DefaultPage,
    Moi,
    Exp,
    Projets,
    Contact
  },
  methods: {
    ScrollToPage(e) {
      document.getElementById(e).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.upp {
  padding-top: 10px;
}
</style>