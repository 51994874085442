<template>
  <div class="projectBox">
    <div class="right">
      <div class="top">
        <h1>{{ projectType }}</h1>
        <h2 @click="modal = !modal">{{ projectName }}</h2>
      </div>
      <div class="bottom">
        <p>{{ projectDesc }}</p>
        <h3 class="stack">{{ projectStack }}</h3>
      </div>
    </div>
    <div class="left" @click="modal = !modal">
      <div class="imgOverlay"></div>
      <img :src="imagesrc" :alt="projectName"/>
    </div>
  </div>
  <transition name="pop">
    <ProjectModal v-if="false"/>
  </transition>
</template>

<script>
import ProjectModal from "./ProjectModal"

export default {
  name: "ProjectCmp",
  components: {
    ProjectModal
  },
  props: {
    projectType : {
      type: String,
      default: "Project Pro/Perso"
    },
    projectName: {
      type: String,
      default: "Project Name"
    },
    projectDesc: {
      type: String,
      default: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab perspiciatis dolor veniam illum reprehenderit eligendi eum quibusdam aspernatur odio voluptatem?"
    },
    projectStack: {
      type: String,
      default: "ReactNative - VueJS - ExpressJS - SocketIO"
    },
    projectImage: {
      type: String,
      default: "notfound.png"
    }
  },
  data() {
    return {
      imagesrc: require("@/assets/images/projets/" + this.projectImage),
      modal: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/scss/variables";
.pop-enter-active {
  animation: bounce-in .4s;
}
.pop-leave-active {
  animation: bounce-in .4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.projectBox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 350px;
  margin-bottom: 70px;

  .right {
    align-self: center;
    width: 100%;
    z-index: 50;
    .bottom {
      width: 75%;
      background: $light-navy;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 3px 3px 3px rgba($dark-navy, .5);
      margin-top: 30px;
      margin-bottom: 30px;
      
      p {
        padding: 0;
        margin: 0;
      }
      h3 {
        font-family: 'IBM Plex Mono', monospace;
        font-size: 11px;
        letter-spacing: 0px;
        color: $green;
      }
    }
  }

  .left {
    position: relative;
    cursor: pointer;
    img {
      position: absolute;
      right: 0;
      height: 100%;
      z-index: -20;
      border-radius: 8px;
      
    }

    .imgOverlay {
      position: absolute;
      background: rgba($green, .5);
      backdrop-filter: contrast(500%);
      width: 560px;
      height: 350px;
      right: 0;
      border-radius: 8px;
      transition: opacity .4s ease-in-out;
      z-index: 1;

      &:hover {
        opacity: 0;
      }
    }
  }
}

h1 {
  font-size: 13px;
  margin: 0;
}

h2 {
  color: $lightest-slate;
  font-size: 25px;
  margin-top: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .projectBox {
    flex-direction: column;
    height: 500px;

    .right {
      .bottom {
        width: 100%;
        padding: 0;
        background: transparent;
      }
    }
  }
  
  .left {
    width: 100%;
    height: 350px;

    img {
      width: 100%;
      
      object-fit: cover;
    }
    .imgOverlay {
      visibility: hidden;
    }
  }
}
</style>