<template>
  <div class="container">
    <div>
      <h2 class="title">{{ title }}</h2>
    </div>
    
  </div>
  <div class="container sloteur">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DefaultPage",
  props: {
    title: {
      type: String,
      default: "Title"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/scss/variables";

.container {
  margin-top: 200px;
  margin-bottom: 20px;
  text-align: justify;
  h2 {
    font-size: 30px;
    color: $lightest-slate;

    &::before {
      content: '/';
      color: $green;
      font-size: 25px;
      margin-right: 25px;
    }

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 30vw;
      height: 0.5px;
      background: $slate;
      margin-left: 25px;
    }
  }
}

.sloteur {
  display: flex;
  margin: 0px auto;
}

@media screen and (max-width: 800px) {
  .container {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: justify;
  h2 {
    font-size: 20px;
    color: $lightest-slate;
    margin-top: 50px;

    &::before {
      content: '/';
      color: $green;
      font-size: 25px;
      margin-right: 25px;
    }

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 30vw;
      height: 0.5px;
      background: $slate;
      margin-left: 25px;
    }
  }
}
}
</style>