<template>
<transition name="fade">
  <div v-if="visible" class="topbar" :class="{detach : !styleBase}">
    <a class="link" @click="$emit('change-menu', 0)">Hi !</a>
    <a class="link" @click="$emit('change-menu', 1)">A Propos</a>
    <a class="link" @click="$emit('change-menu', 2)">Expériences</a>
    <a class="link" @click="$emit('change-menu', 3)">Projets</a>
    <a class="link" @click="$emit('change-menu', 4)">Contact</a>
    <Button class="btn" :windowed="true" link="http://benoit.myutopie.fr/cv.pdf">mon CV</Button>
  </div>
</transition>
</template>

<script>
import Button from './Button'

export default {
  name: "TopBar",
  components: {
    Button
  },
  data() {
    return {
      scrollValue: 0,
      visible: true,
      styleBase: true
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousemove', this.handleMouse);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousemove', this.handleMouse);
  },
  methods: {
    handleScroll () {
      const scroll = window.scrollY;

      if(scroll == 0) {
        this.visible = true
        this.styleBase = true
      } else if(scroll > this.scrollValue && scroll != 0) {
        this.visible = false
        this.styleBase = false
      } else {
        this.visible = true
      }
      this.scrollValue = window.scrollY;
    },
    handleMouse(e) {
      if(e.y <= 40 && !this.visible) {
        this.visible = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/scss/variables";
.fade-enter-active {
  animation: bounce-in .4s;
}
.fade-leave-active {
  animation: bounce-in .4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translateY(-100%);
  }
}

.topbar {
  background: $dark-navy;
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all .3s ease-in-out;
  z-index: 1000;

  .link {
    position: relative;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 13px;
    color: $lightest-slate;
    text-decoration: none;
    margin: 0px 15px;
    transition: all 200ms ease-in;
    cursor: pointer;

    &:hover {
      color: $green;
      transform: translateX(3px);

      &::before {
        // transform: translateY(5px);
        opacity: 1;
      }
    }
    &::before {
      content: "/ ";
      color: $green;
      opacity: 0;
      transition: all 200ms ease-in;
    }
  }

  .btn {
    width: 80px;
    height: 35px;
    margin: 0px 20px;
  }
}
.detach {
  background: rgba($navy, .95);
  height: 70px;
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 20px black;
}
.topbarmini {
  display: none;
  background: $dark-navy;
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all .3s ease-in-out;
  z-index: 1000;

  .link {
    position: relative;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 13px;
    color: $lightest-slate;
    text-decoration: none;
    margin: 0px 15px;
    transition: all 200ms ease-in;
    cursor: pointer;

    &:hover {
      color: $green;
      transform: translateX(3px);

      &::before {
        // transform: translateY(5px);
        opacity: 1;
      }
    }
    &::before {
      content: "/ ";
      color: $green;
      opacity: 0;
      transition: all 200ms ease-in;
    }
  }

  .btn {
    width: 80px;
    height: 35px;
    margin: 0px 20px;
  }
}
@media screen and (max-width: 660px) {
  .topbar {
    display: none;
  }
  .topbarmini {
    display: none;
  }
}
</style>