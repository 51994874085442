<template>
<div class="container">
  <div class="base">
    <h1>Salut, je suis...</h1>
    <h2 class="big">Benoit DECANTER.</h2>
    <h2>Je construit de belles choses pour le web.</h2>
    <p>Je suis un développeur Full Stack basé à Lille, France, spécialisé dans la création de sites Web, d'applications et de tout le reste...</p>
    <Button :windowed="true" link="mailto:benoit@myutopie.fr">Contactez-moi</Button>
  </div>
</div>
</template>

<script>
import Button from "@/components/Button"

export default {
  name: "Hi",
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
.base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
p {
  width: 60%;
  text-align: justify;
}

@media screen and (max-width: 660px) {
  .base {
    margin-top: 0px;
  }
  h2 {
    font-size: 50px;
  }
  p {
  width: 100%;
  text-align: justify;
}
}

</style>