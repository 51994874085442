<template>
<div class="con">
  <div class="contentL">
    <a @click="selectMenu(1)" class="jobLink" :class="{sel : selected == 1}">Kinomap</a>
    <a @click="selectMenu(2)" class="jobLink" :class="{sel : selected == 2}">Synbioz</a>
    <a @click="selectMenu(3)" class="jobLink" :class="{sel : selected == 3}">myUtopie</a>
    <a @click="selectMenu(4)" class="jobLink" :class="{sel : selected == 4}">EHCG</a>
  </div>
  <div class="contentR">

     <!-- KINOMAP -->
    <transition name="fadein">
    <div v-if="selected == 1">
      <h2>Développeur Full Stack <a href="https://www.kinomap.com/fr/" target="_blank" class="link-a">@ Kinomap</a></h2>
      <h3>Fevr. 2021 - Today</h3>
      <div class="base">
        <p class="sup">/</p>
        <p>Front : Prise en charge des projets Front sur Angular et reprise des anciens projets (Twig).</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>Back : Création endPoint Api (Laravel | Symfony), DB (PostgreSql), Middleware (Express | Socket.io).</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>DevOps : Gestion des ressources sur Kibana, création de nouveaux serveurs et parametrages, gestion des middleswares (pm2).</p>
      </div>
    </div>
    </transition>

    <!-- SYNBIOZ -->
    <transition name="fadein">
    <div v-if="selected == 2">
      <h2>Développeur Front <a href="https://www.synbioz.com/" target="_blank" class="link-a">@ Synbioz</a></h2>
      <h3>Août 2020 - Sept. 2020</h3>
      <div class="base">
        <p class="sup">/</p>
        <p>Développement d'une interface administrateur en connexion avec une RTDB (Real Time DataBase) Firebase.</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>Front : Création d'un template Admin avec VueJS (VueX, VueRouter) & Bootstrap.</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>Back : Google Firebase (Auth : User & Admin), stockage des données (RTDB), stockage des assets (Storage). Création de fonctions back Firebase (gestion des entrées Data, verification et modification image Storage).</p>
      </div>
    </div>
    </transition>
    <!-- myUtopie -->
    <transition name="fadein">
    <div v-if="selected == 3">
      <h2>Gérant <a href="https://myutopie.fr/" target="_blank" class="link-a">@ myUtopie</a></h2>
      <h3>Juil. 2018 - Mai 2020</h3>
      <div class="base">
        <p class="sup">/</p>
        <p>Nous concevons et accompagnons dans leurs transformations digitales les entreprises, pour les aider à développer rapidement leur présence sur le web et les réseaux sociaux, en utilisant une méthodologie adaptée à leurs métiers, une expertise et une passion liée au web et au graphisme.</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>Développement Web (Wordpress & co) - Automatisation - Référencement SEO, SMO & SMA. <br/>
        Création web diverses : Templating, Emailing responsive (MJML & Foundation for Emails).<br/>
        Gestion des médias sociaux (Facebook, Instagram, Linkedin).</p>
      </div>
    </div>
    </transition>
    <!-- EHCG -->
    <transition name="fadein">
    <div v-if="selected == 4">
      <h2>Directeur Studio Graphique & Digital <a target="_blank" href="http://www.ehcg.fr/" class="link-a">@ EHCG</a></h2>
      <h3>Févr. 2016 - Avr. 2018</h3>
      <div class="base">
        <p class="sup">/</p>
        <p>Gestion du Studio, management des equipes, support technique commerciaux.</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>Développement d'application & mini-jeux (JS, Phaser.io, Ionic)</p>
      </div>
      <div class="base">
        <p class="sup">/</p>
        <p>Gestion de la Production Prépresse:<br/>
          - Gestion et formation des équipes<br/>
          - Gestion des médias sociaux<br/>
          - Gestion des flux de productions (colorimétrie avancé, process de contrôle, automatisation)<br/>
          - Etude de faisabilité (Impression, emailing, jeux et application web)<br/>
          - Création web (Vitrine & Ecommerce)<br/>
        </p>
      </div>
      
    </div>
    </transition>
  </div>
</div>
</template>

<script>
export default {
  name: "Exp",
  data() {
    return {
      selected: 1
    }
  },
  methods: {
    selectMenu(value) {
      this.selected = value;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/scss/variables";

.fadein-enter-active {
  animation: opa .3s;
}
@keyframes opa {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.con {
  width: 100%;
  display: flex;
  flex-direction: columns;
  margin: auto;
}

.link-a {
  text-decoration: none;
  color: $green;
  display: inline-block;
  position: relative;

  &:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: -2px;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: $green;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after { 
    width: 100%; 
    left: 0; 
  }
}
.contentL {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 200px;
  height: 200px;

  a {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 14px;
    text-decoration: none;
    padding: 0;
    margin: 0;
    height: 100%;
    padding-left: 15px;
    padding-top: 23px;
    transition: all .2s ease-in;
    color: $light-slate;
    cursor: pointer;

    &:hover {
      background: rgba($light-navy, 1);
      color: $green;
    }
  }
  .sel {
    background: rgba($light-navy, 1);
    color: $green;
  }
}

.contentR {
  padding: 15px;
  width: 70%;
  h2 {
    font-size: 20px;
    color: $lightest-slate;
    margin: 0;
  }
  h3 {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 12px;
    margin-top: 8px;
  }
  p {
    font-size: 15px;
    color: $light-slate;
  }

  .base {
    display: flex;

    .sup {
      margin-right: 20px;
      font-size: 16px;
      font-family: 'IBM Plex Mono', monospace;
      color: $green;
    }
  }
}

@media screen and (max-width: 660px) {
  .con {
    flex-direction: column;
  }
  .contentL {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 50px;

    a {
      width: 100%;
      text-align: center;
      padding: 0;
      padding-top: 25px;
    }
  }
  .contentR {
    width: 100%;
    padding: 0;
    padding-top: 40px;

    h2 {
    font-size: 15px;
    color: $lightest-slate;
    margin: 0;
    }
    h3 {
      font-family: 'IBM Plex Mono', monospace;
      font-size: 12px;
      margin-top: 8px;
    }
    p {
      margin-bottom: 5px;
    }
  }
}

</style>