<template>
  <a :href="link" class="Button" :target="windowed ? '_blank' : '' ">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    windowed: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    }
  }
}
</script>