<template>

  <!-- <div class="supp">
    <div class="boite">
      <div class="right">
        <h1>Pro Projet</h1>
        <h2>Portail Admin</h2>
      </div>
      <div class="box">
        <div class="image">
          <div class="imgOverlay"></div>
          <img src="@/assets/images/projets/syn.png" alt="what">
        </div>
        <div class="text right">
          <p>
            Portail Admin de gestion de commande<br>
            Front VueJS (CLI - VueX - VueRouter)<br>
            Back Firebase (RTDB - Auth - Storage - Functions)
          </p>
        </div>
      </div>
      <div class="tech right">
        <p>VueJS - S(CSS) - Google Firebase</p>
      </div>
    </div>

    <div class="boite">
      <div class="right">
        <h1>Pro Projet</h1>
        <h2>Homycab</h2>
      </div>
      <div class="box">
        <div class="image">
          <div class="imgOverlay"></div>
          <img src="@/assets/images/projets/homycab.png" alt="what">
        </div>
        <div class="text right">
          <p>
            Développement d'un ensemble d'outils destiné à une entreprise de gestion de taxi (Finlande) orientée conducteur.<br>
            Création application mobile, portail administrateur & API temps réel
          </p>
        </div>
      </div>
      <div class="tech right">
        <p>ReactNative - VueJS - ExpressJS - SocketIO</p>
      </div>
    </div>

  </div> -->


  <div class="contain">
    <ProjectCmp 
      projectType="Pro Projet"
      projectName="Portail Admin"
      projectDesc="Portail Admin de gestion de commande, Front: VueJS (CLI - VueX - VueRouter), Back: Firebase (RTDB - Auth - Storage - Functions)"
      projectStack="VueJS - S(CSS) - Google Firebase"
      projectImage="syn.png"
    />
    <ProjectCmp 
      projectType="Pro Projet"
      projectName="Kode - Homycab"
      projectDesc="Développement d'un ensemble d'outils destiné à une entreprise de gestion de taxi (Finlande) orientée conducteur. 
                  Création application mobile, portail administrateur & API temps réel"
      projectStack="ReactNative - VueJS - ExpressJS - SocketIO"
      projectImage="homycab.png"
    />
  </div>
  

</template>

<script>
import ProjectCmp from "@/components/ProjectCmp"

export default {
  name: "Projets",
  components: {
    ProjectCmp
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/scss/variables";
.contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.boite {
  margin-bottom: -100px;
  padding: 0;
}

.supp {
  width: 100%;
}
.right {
  text-align: right;
  margin-bottom: -40px;
}
.imgOverlay {
  position: absolute;
  background: rgba($green, .5);
  backdrop-filter: contrast(500%);
  width: 50%;
  height: 100%;
  border-radius: 5px;
  transition: opacity .4s ease-in-out;

  &:hover {
    opacity: 0;
  }
}
h1 {
  font-size: 13px;
  margin: 0;
}

h2 {
  color: $lightest-slate;
  font-size: 25px;
  margin-top: 5px;
}
.tech {
  width: 50%;
  height: 200px;
  p {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
  }
}
.box {
  width: 100%;
  display: flex;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    img {
      width: 50%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .text {
    width: 70%;
    background: $light-navy;
    position: absolute;
    right: 0;
    top: 20%;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba($dark-navy, .5);

    p {
      margin: 0;
      font-size: 16px;
      color: $light-slate;
    }
  }
}


@media screen and (max-width: 700px) {
  .right {
    text-align: right;
    margin-bottom: 0;
  }
  h1, h2 {
    text-align: center;
  }
  .box {
  width: 100%;
  display: flex;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .text {
    width: 100%;
    position: absolute;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    padding: 15px;
    top: 0;

    p {
      margin: 0;
      font-size: 17px;
      color: $navy;
      text-align: justify;
      margin-left: 25px;
    }
  }
}
.imgOverlay {
  position: absolute;
  background: rgba($green, .95);
  backdrop-filter: contrast(500%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: opacity .4s ease-in-out;

  &:hover {
    opacity: 0;
  }
}
.tech {
  width: 100%;
  height: 200px;
  p {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
  }
}
}

</style>